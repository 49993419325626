import React, { useState } from "react";
import "./Process.css";
import { useTranslation } from "react-i18next";

export default function Process() {
  const [currentStep, setCurrentStep] = useState(1);
  const { t } = useTranslation();

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const sectionContent = [
    {
      step: 1,
      picture: "/pictures/process/meeting.jpg",
      alt: "meeting",
      title: t("PROCESS.STEP1-TITLE"),
      content: t("PROCESS.STEP1-CONTENT"),
    },
    {
      step: 2,
      picture: "/pictures/process/wireframe.jpg",
      alt: "wireframe",
      title: t("PROCESS.STEP2-TITLE"),
      content: t("PROCESS.STEP2-CONTENT"),
    },
    {
      step: 3,
      mp4: "/videos/design-background.mp4",
      title: t("PROCESS.STEP3-TITLE"),
      content: t("PROCESS.STEP3-CONTENT"),
    },
    {
      step: 4,
      picture: "/pictures/process/development.jpg",
      alt: "web-development",
      title: t("PROCESS.STEP4-TITLE"),
      content: t("PROCESS.STEP4-CONTENT"),
    },
    {
      step: 5,
      picture: "/pictures/process/testing.jpg",
      alt: "testing",
      title: t("PROCESS.STEP5-TITLE"),
      content: t("PROCESS.STEP5-CONTENT"),
    },
    {
      step: 6,
      picture: "/pictures/process/presentation.jpg",
      alt: "product",
      title: t("PROCESS.STEP6-TITLE"),
      content: t("PROCESS.STEP6-CONTENT"),
    },
    {
      step: 7,
      picture: "/pictures/process/operation.jpg",
      alt: "operational-system",
      title: t("PROCESS.STEP7-TITLE"),
      content: t("PROCESS.STEP7-CONTENT"),
    },
  ];

  const activeSection = sectionContent.find(
    (section) => section.step === currentStep
  );

  return (
    <div className="process" id="HowMade">
      <h1>{t("PROCESS.TITLE")}</h1>
      <div className="process-wrapper">
        <div id="progress-bar-container">
          <ul>
            {[1, 2, 3, 4, 5, 6, 7].map((step) => (
              <li
                key={step}
                className={`step step0${step} ${
                  currentStep === step ? "active" : ""
                }`}
                onClick={() => handleStepClick(step)}
              >
                <div className="step-inner">
                  <span className="step-text">{t("PROCESS.STEP")}</span>
                  {step}
                </div>
              </li>
            ))}
          </ul>
          <div id="line">
            <div
              id="line-process"
              style={{ width: `${(currentStep - 1) * 16.6}%` }}
            ></div>
          </div>
        </div>

        <div id="progress-content-section">
          <div className="section-content">
            {activeSection ? (
              <>
                <div className="section-media">
                  {activeSection.mp4 ? (
                    <video autoPlay muted className="video-progress">
                      <source src={activeSection.mp4} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      className="step-picture"
                      src={activeSection.picture}
                      alt={activeSection.alt}
                    />
                  )}
                </div>
                <div className="section-content-text">
                  <h2 className="progress-step-title">{activeSection.title}</h2>
                  <p>{activeSection.content}</p>
                </div>
              </>
            ) : (
              <h2>No content available for this step</h2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
