import "./PriceCard.css";
import { useTranslation } from "react-i18next";

const PriceCard = () => {
  const { t } = useTranslation();

  return (
    <div className="price-card">
      <div className="card card-basic">
        <h1 className="card-title">{t("PRICE-CARD.CARD1-TITLE")}</h1>
        <div className="card-body">
          <div className="card-price">
            <p>
              {t("PRICE-CARD.CARD1-CURRENCY")}
              <span className="card-price-value">
                {t("PRICE-CARD.CARD1-PRICE")}
              </span>
            </p>
            <p>{t("PRICE-CARD.CARD1-FROM")}</p>
          </div>
          <div className="card-features">
            <ul>
              <li>{t("PRICE-CARD.CARD1-LI1")}</li>
              <li>{t("PRICE-CARD.CARD1-LI2")}</li>
              <li>{t("PRICE-CARD.CARD1-LI3")}</li>
              <li>{t("PRICE-CARD.CARD1-LI4")}</li>
              <li>{t("PRICE-CARD.CARD1-LI5")}</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card card-standard">
        <h1 className="card-title">{t("PRICE-CARD.CARD2-TITLE")}</h1>
        <div className="card-body">
          <div className="card-price">
            <p>
              {t("PRICE-CARD.CARD2-CURRENCY")}
              <span className="card-price-value">
                {t("PRICE-CARD.CARD2-PRICE")}
              </span>
            </p>
            <p>{t("PRICE-CARD.CARD2-FROM")}</p>
          </div>
          <div className="card-features">
            <ul>
              <li>{t("PRICE-CARD.CARD2-LI1")}</li>
              <li>{t("PRICE-CARD.CARD2-LI2")}</li>
              <li>{t("PRICE-CARD.CARD2-LI3")}</li>
              <li>{t("PRICE-CARD.CARD2-LI4")}</li>
              <li>{t("PRICE-CARD.CARD2-LI5")}</li>
              <li>{t("PRICE-CARD.CARD2-LI6")}</li>
              <li>{t("PRICE-CARD.CARD2-LI7")}</li>
              <li>{t("PRICE-CARD.CARD2-LI8")}</li>
              <li>{t("PRICE-CARD.CARD2-LI9")}</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card card-premium">
        <h1 className="card-title">{t("PRICE-CARD.CARD3-TITLE")}</h1>
        <div className="card-body">
          <div className="card-price">
            <p>
              {t("PRICE-CARD.CARD3-CURRENCY")}
              <span className="card-price-value">
                {t("PRICE-CARD.CARD3-PRICE")}
              </span>
            </p>
            <p>{t("PRICE-CARD.CARD3-FROM")}</p>
          </div>
          <div className="card-features">
            <ul>
              <li>{t("PRICE-CARD.CARD3-LI1")}</li>
              <li>{t("PRICE-CARD.CARD3-LI2")}</li>
              <li>{t("PRICE-CARD.CARD3-LI3")}</li>
              <li>{t("PRICE-CARD.CARD3-LI4")}</li>
              <li>{t("PRICE-CARD.CARD3-LI5")}</li>
              <li>{t("PRICE-CARD.CARD3-LI6")}</li>
              <li>{t("PRICE-CARD.CARD3-LI7")}</li>
              <li>{t("PRICE-CARD.CARD3-LI8")}</li>
              <li>{t("PRICE-CARD.CARD3-LI9")}</li>
              <li>{t("PRICE-CARD.CARD3-LI10")}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
