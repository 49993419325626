import PriceCard from "./PriceCard";
import "./Pricing.css";
import { useTranslation } from "react-i18next";

const Pricing = () => {
  const { t } = useTranslation();

  return (
    <section className="pricing">
      <div className="pricing-title-content">
        <span>{t("PRICING.TITLE")}</span>
        <h2>{t("PRICING.H2")}</h2>
        <p>{t("PRICING.H2")}</p>
      </div>
      <PriceCard />
    </section>
  );
};

export default Pricing;
