import React from "react";
import "./PrivacyPolicy.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import Languagepicker from "../../components/Navbar/Languagepicker";
import Privacidad from "../../components/AdatvedelmiSzabalyzat/ESP/Privacidad";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy-page">
      <div className="privacy-policy-page-nav">
        <Link to="/" className="back-home-page-link">
          <p>
            <IoArrowBackOutline />
            {t("NAV_BAR.LINK1")}
          </p>
        </Link>
        <Languagepicker />
      </div>
      <Privacidad />
    </div>
  );
};

export default PrivacyPolicy;
