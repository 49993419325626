import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Languagepicker.css";
import { useTranslation } from "react-i18next";

export default function Languagepicker() {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const { i18n } = useTranslation();

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    setShowOptions(false); // Close the language options dropdown after selecting a language
  };

  useEffect(() => {
    const fetchLocationAndSetLanguage = async () => {
      try {
        // Example API: Replace this with a real IP geolocation API
        const response = await axios.get("https://ipapi.co/json/");
        const countryCode = response.data.country_code;

        let language = "esp"; // default

        const spanishSpeakingCountries = [
          "AR",
          "BO",
          "CL",
          "CO",
          "CR",
          "CU",
          "DO",
          "EC",
          "SV",
          "GQ",
          "GT",
          "HN",
          "MX",
          "NI",
          "PA",
          "PY",
          "PE",
          "PR",
          "ES",
          "UY",
          "VE",
        ];

        if (spanishSpeakingCountries.includes(countryCode)) {
          language = "esp";
        } else if (countryCode === "HU") {
          language = "hu";
        }

        handleLanguageChange(language);
      } catch (error) {
        console.error("Error fetching IP location", error);
        // If there's an error, you can default to "en" or any other language
        handleLanguageChange("esp");
      }
    };

    fetchLocationAndSetLanguage();
  }, []); // Empty dependency array ensures it runs only once on mount

  return (
    <div className="languagepicker">
      <div className="dropdown">
        <button
          className="dropbtn"
          onClick={() => setShowOptions(!showOptions)}
        >
          {selectedLanguage.toUpperCase()}
        </button>
        {showOptions && (
          <div className="dropdown-content">
            <button onClick={() => handleLanguageChange("en")}>
              <img
                src="/pictures/icons/united-kingdom.png"
                alt="english flag"
                width={20}
              />
              EN
            </button>
            <button onClick={() => handleLanguageChange("esp")}>
              <img
                src="/pictures/icons/Spain.png"
                alt="spanish flag"
                width={20}
              />
              ESP
            </button>
            <button onClick={() => handleLanguageChange("hu")}>
              <img
                src="/pictures/icons/hungary.png"
                alt="hungary flag"
                width={20}
              />
              HU
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
