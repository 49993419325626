import "./Servicecard.css";

export default function ServiceCard(prop) {
  return (
    <div className="servicecard">
      <img src={prop.icon} alt={prop.alt} />
      <h3>{prop.title}</h3>
      <p>{prop.text}</p>
    </div>
  );
}
