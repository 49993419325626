import React from "react";
import { useRef } from "react";
import "./ContactForm.css";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from "react-router-dom";

const ContactForm = () => {
  const form = useRef();
  const { t } = useTranslation();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Email sent successfully", {
            position: "top-center",
          });
          form.current.reset(); // Reset the form fields
        },
        (error) => {
          console.log(error.text);
          toast.error("Error sending email");
        }
      );
  };

  return (
    <section className="contact" id="contact">
      <h2>{t("CONTACT.TITLE")}</h2>
      <div className="contact-content">
        <form className="form" ref={form} onSubmit={sendEmail}>
          <p>{t("CONTACT.NAME")}</p>
          <input
            type="text"
            placeholder={t("CONTACT.NAME_PLACEHOLDER")}
            name="from_name"
            required
          />
          <p>{t("CONTACT.EMAIL")}</p>
          <input
            type="email"
            placeholder={t("CONTACT.EMAIL_PLACEHOLDER")}
            name="user_email"
            required
          />
          <p>{t("CONTACT.MESSAGE")}</p>
          <textarea
            name="message"
            placeholder={t("CONTACT.MESSAGE_PLACEHOLDER")}
            required
          />
          <div className="checkbox">
            <input type="checkbox" id="consent" name="consent" required />
            <label htmlFor="consent">
              {t("CONTACT.CONSENT_LABEL_START")}{" "}
              <NavLink to="/privacy_policy">
                {t("CONTACT.CONSENT_LINK")}
              </NavLink>
            </label>
          </div>
          <input type="submit" className="submit" value={t("CONTACT.BUTTON")} />
        </form>
        <img src="/pictures/contact/contact_image.jpg" alt="" />
      </div>
      <ToastContainer />
    </section>
  );
};

export default ContactForm;
