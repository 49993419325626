import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
  ;
}

export default App;
