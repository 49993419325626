import React from "react";
import ServiceCard from "./ServiceCard";
import "./Services.css";
import { useTranslation } from "react-i18next";

export default function Services() {
  const { t } = useTranslation();

  const data = [
    {
      id: 1,
      icon: "/pictures/icons/laptop.png",
      alt: "laptop",
      title: t("SERVICES.CARD1-TITLE"),
      text: t("SERVICES.CARD1-TEXT"),
    },
    {
      id: 2,
      icon: "/pictures/icons/domain.png",
      alt: "domain",
      title: t("SERVICES.CARD2-TITLE"),
      text: t("SERVICES.CARD2-TEXT"),
    },
    {
      id: 3,
      icon: "/pictures/icons/hosting.png",
      alt: "hosting",
      title: t("SERVICES.CARD3-TITLE"),
      text: t("SERVICES.CARD3-TEXT"),
    },
    {
      id: 4,
      icon: "/pictures/icons/growth-graph.png",
      alt: "growth-graph",
      title: t("SERVICES.CARD4-TITLE"),
      text: t("SERVICES.CARD4-TEXT"),
    },
    {
      id: 5,
      icon: "/pictures/icons/seo.png",
      alt: "seo",
      title: t("SERVICES.CARD5-TITLE"),
      text: t("SERVICES.CARD5-TEXT"),
    },
    {
      id: 6,
      icon: "/pictures/icons/social-marketing.png",
      alt: "social-marketing",
      title: t("SERVICES.CARD6-TITLE"),
      text: t("SERVICES.CARD6-TEXT"),
    },
  ];

  return (
    <div className="services" id="services">
      <h2 className="service-title">{t("SERVICES.TITLE")}</h2>
      <div className="cards-wrap">
        {data.map((item) => (
          <ServiceCard
            key={item.id}
            icon={item.icon}
            alt={item.alt}
            title={item.title}
            text={item.text}
          />
        ))}
      </div>
    </div>
  );
}
