import React from "react";
import "./Footer.css";
import { PiCopyrightThin } from "react-icons/pi";

import { FaFacebook, FaInstagram, FaPhone, FaLinkedin } from "react-icons/fa";
import { FaMapLocation } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { IoIosMail } from "react-icons/io";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="container">
        <div className="company-info">
          <h1>IN-A solutions S.A.C.</h1>
          <div className="company-availability">
            <p>
              <FaPhone /> +51 (982) 028-037
            </p>
            <p>
              <FaMapLocation /> Jr. Carlos Arrieta 190 Lima Peru
            </p>
            <p>
              <IoIosMail /> inasolutionssac@gmail.com
            </p>
          </div>
        </div>

        <div className="fast-links">
          <h1>{t("FOOTER.LINKS_TITLE")}</h1>
          <ul>
            <li>
              <a href="#home">{t("FOOTER.LINK1")}</a>
            </li>
            <li>
              <a href="#services">{t("FOOTER.LINK2")}</a>
            </li>
            <li>
              <a href="#contact">{t("FOOTER.LINK3")}</a>
            </li>
            <li>
              <a href="#HowMade">{t("FOOTER.LINK4")}</a>
            </li>
            <li>
              <a href="#home">{t("FOOTER.LINK5")}</a>
            </li>
          </ul>
        </div>

        <div className="company-follow">
          <h1>{t("FOOTER.FOLLOW")}</h1>
          <div className="company-follow-icons">
            <a
              href="https://www.facebook.com/profile.php?id=61564972262694"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="facebook-icon" />
            </a>
            <FaInstagram className="insta-icon" />
            <FaLinkedin className="linkedin-icon" />
          </div>
        </div>
      </div>
      <p className="copyright">
        <PiCopyrightThin />
        {t("FOOTER.COPYRIGHT")}
      </p>
    </footer>
  );
}
