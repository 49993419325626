import "./Howmadesection.css";
import { useTranslation } from "react-i18next";

const HowMadeSection = () => {
  const { t } = useTranslation();

  return (
    <section className="how-made">
      <div className="how-made-bg">
        <div className="how-made-content">
          <h2>{t("HOW-MADE.TITLE")}</h2>
          <p>{t("HOW-MADE.TEXT")}</p>
          <a href={"#HowMade"}>
            <button className="btn-style">{t("HOW-MADE.BUTTON")}</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default HowMadeSection;
