import "./Navbar.css";
import React, { useState } from "react";
import Languagepicker from "./Languagepicker";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const { t } = useTranslation();

  const Menu = () => (
    <>
      <Link
        to="/"
        className="navbar_link"
        onClick={() => {
          setHamburgerMenuOpen(!hamburgerMenuOpen);
        }}
      >
        <p>{t("NAV_BAR.LINK1")}</p>
      </Link>
      <a
        href="#services"
        className="navbar_link"
        onClick={() => {
          setHamburgerMenuOpen(!hamburgerMenuOpen);
        }}
      >
        <p>{t("NAV_BAR.LINK3")}</p>
      </a>
      <a
        href="#contact"
        className="navbar_link"
        onClick={() => {
          setHamburgerMenuOpen(!hamburgerMenuOpen);
        }}
      >
        <p>{t("NAV_BAR.LINK4")}</p>
      </a>
      <a
        href="#HowMade"
        className="navbar_link"
        onClick={() => {
          setHamburgerMenuOpen(!hamburgerMenuOpen);
        }}
      >
        <p>{t("NAV_BAR.LINK5")}</p>
      </a>
    </>
  );

  return (
    <div className="navbar">
      <div className="navbar-logo">
        <img src="/pictures/In-Ka logo pic.png" alt="In-Ka Logo" />
      </div>
      <div
        className={`hamburger-menu ${hamburgerMenuOpen ? "x" : ""}`}
        onClick={() => {
          setHamburgerMenuOpen(!hamburgerMenuOpen);
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div
        className={`navbar-links_container ${hamburgerMenuOpen ? "open" : ""}`}
      >
        <Menu />
        <div className="language-picker_container">
          <Languagepicker />
        </div>
      </div>
    </div>
  );
}
