import "./Innovative.css";
import { useTranslation } from "react-i18next";

const Innovative = () => {
  const { t } = useTranslation();
  const data = [
    {
      id: 1,
      image: "/pictures/innovative/responsive.jpg",
      alt: "responsive displays",
      tittle: t("INNOVATIVE.CARD1-TITLE"),
      text: t("INNOVATIVE.CARD1-TEXT"),
    },
    {
      id: 2,
      image: "/pictures/innovative/commers_solutions.jpg",
      alt: "",
      tittle: t("INNOVATIVE.CARD2-TITLE"),
      text: t("INNOVATIVE.CARD2-TEXT"),
    },
    {
      id: 3,
      image: "/pictures/innovative/UX_optimization.jpg",
      alt: "",
      tittle: t("INNOVATIVE.CARD3-TITLE"),
      text: t("INNOVATIVE.CARD3-TEXT"),
    },
  ];

  return (
    <section className="innovative">
      <div className="text-content">
        <p>
          <span>{t("INNOVATIVE.TITLE")}</span>
        </p>
        <h2>{t("INNOVATIVE.H2")}</h2>
        <p>{t("INNOVATIVE.TEXT")}</p>
      </div>
      <div className="innovative-card-wrapper">
        {data.map((item) => (
          <div key={item.id} className="innovative-card">
            <img src={item.image} alt={item.alt} className="innovative-img" />
            <h3>{item.tittle}</h3>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Innovative;
