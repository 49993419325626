import "./Welcome.css";
import { useTranslation } from "react-i18next";
import Navbar from "../Navbar/Navbar";

export default function Welcome() {
  const { t } = useTranslation();

  return (
    <div className="welcome">
      <Navbar />
      <video autoPlay loop muted className="video-bg">
        <source src="/videos/welcome-backround.mp4" type="video/mp4" />
      </video>
      <div className="company-logo">
        <img src="/pictures/In-Ka logo pic.png" alt="logo" />
        <h2>IN-A </h2>
        <p>Solutions S.A.C.</p>
      </div>
      <p className="slogan">Innovation, the key to the Achievement</p>
      <div className="title-box">
        <div className="title">
          <p>{t("WELCOME.TEXT1")}</p>
          <span>{t("WELCOME.TEXT2")}</span>
        </div>
      </div>
    </div>
  );
}
