import "./Introductory.css";
import { useTranslation } from "react-i18next";

export default function Introductory() {
  const { t } = useTranslation();

  return (
    <section className="introductory">
      <div className="introductory-title-content">
        <h1>{t("INTRODUCTORY.H1")}</h1>
        <p>{t("INTRODUCTORY.TEXT1")}</p>
      </div>
      <div className="introductory-wrapper">
        <div className="int-pic">
          <img
            src="/pictures/landing/screens_time_square.jpg"
            alt="screens on time squers"
          />
        </div>
        <div className="text-box">
          <h2>{t("INTRODUCTORY.H2")}</h2>
          <p>{t("INTRODUCTORY.TEXT2")}</p>
          <p>{t("INTRODUCTORY.TEXT3")}</p>
        </div>
      </div>
    </section>
  );
}
