import Welcome from "../components/Welcome/Welcome";
import Introductory from "../components/Introductory/Introductory";
import Services from "../components/Services/Services";
import { FaWhatsapp } from "react-icons/fa";
import Innovative from "../components/Innovative/Innovative";
import HowMadeSection from "../components/HowMadeBanner/HowMadeSection";
import Pricing from "../components/Pricing/Pricing";
import ContactForm from "../components/Contact/ContactForm";
import Footer from "../components/Footer/Footer";
import Process from "../components/Process/Process";
import "./home.css";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { useEffect, useState } from "react";

export default function Landing() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {isLoading && <LoadingScreen />}
      {/* A komponensek megjelennek, de a loading képernyő addig eltakarja őket */}
      <div className="landing">
        <a href="https://wa.me/+51982028037" target="_blank" rel="noreferrer">
          <FaWhatsapp className="whatsup" />
        </a>
        <Welcome />
        <Introductory />
        <Innovative />
        <HowMadeSection />
        <Services />
        <Process />
        <Pricing />
        <ContactForm />
        <Footer />
      </div>
    </>
  );
}
