import React from "react";
import "./Privacidad.css";

const Privacidad = () => {
  return (
    <section className="privacidad">
      <h1>Políticas de Privacidad</h1>
      <p>
        La presente política de privacidad de Datos Personales (la «Política de
        Privacidad»), tiene por finalidad informarle la manera en que IN-A
        Solutions S.A.C. , con R.U.C. N° 20612603449, con domicilio en Calle Jr
        Carlos Arrieta 190, Barranco, Lima, realiza el tratamiento de los Datos
        Personales que recopila de sus Usuarios a través de consentimientos
        escritos u otorgados mediante el llenado de formularios electrónicos que
        se encuentran en el sitio, «Sitio Web»).{" "}
      </p>
      <p>
        Para efectos de esta Política de Privacidad toda referencia a «nosotros»
        se entiende a IN-A Solutions S.A.C. y cuando se refiera al «Usuario» se
        entiende a todas aquellas personas que naveguen, utilicen y/o realicen
        compras a través del Sitio Web de IN-A Solutions S.A.C. aseguramos la
        máxima seguridad y protección de los Datos Personales de titularidad de
        nuestros Usuarios.
      </p>
      <p>
        Le agradecemos leer esta Política de Privacidad antes de proporcionarnos
        sus datos personales de manera facultativa y, si está de acuerdo, marcar
        el recuadro de aceptación de manera previa y expresa. Estos datos son
        necesarios para cumplir con las finalidades descritas en la presente
        Política de Privacidad por lo que, al no permitir su tratamiento,
        impediría estar en condiciones para cumplir las mismas.
      </p>
      <h2>1. Marco normativo y principios rectores</h2>
      <p>
        Esta Política de Privacidad se encuentra regulada por la legislación
        peruana y en particular por:
      </p>
      <p>Ley No. 29733 (en adelante, «Ley»). </p>
      <p>
        Decreto Supremo No. 003-2013-JUS, que aprueba el Reglamento de la Ley
        No. 29733 (en adelante, «Reglamento»).
      </p>
      <p>
        Directiva de Seguridad de la Información, aprobada por la Resolución
        Directoral No. 019-2013-JUS/DGPDP (en adelante, «Directiva») y No.
        1353-2017.
      </p>
      <p>Ley No. 29571, Código de Protección y Defensa del Consumidor</p>
      <h2>2. ¿Qué información recolectamos?</h2>
      <p>IN-A Solutions S.A.C. recopila la siguiente información:</p>
      <p>
        Datos personales proporcionados libremente por el Usuario al momento de
        registrarse, crearse una cuenta y hacer una compra. Cuando el Usuario se
        registra para hacer una compra mediante el Sitio web de IN-A Solutions
        S.A.C., debe completar formularios donde se le solicita proporcionar su
        nombre, apellidos, número de DNI/CE, número de celular, número de
        teléfono fijo, dirección de domicilio y correo electrónico.
      </p>
      <p>Sobre la veracidad de los Datos Personales</p>
      <p>
        El Usuario declara que los Datos Personales proporcionados son
        verdaderos, completos y exactos. Cada Usuario es responsable por la
        veracidad, exactitud, vigencia y autenticidad de los Datos Personales
        suministrados y se compromete a mantenerlos debidamente actualizados.
        IN- A Solutiones S.A.C. no se hace responsable de la veracidad de la
        información que no sea de elaboración propia, por lo que tampoco asume
        responsabilidad alguna por posibles daños o perjuicios que pudieran
        originarse por el uso de dicha información.
      </p>
      <h2>3. Finalidad del tratamiento de los datos</h2>
      <p>
        Todos los datos que nos facilites en los apartados de la Web, los
        incorporaremos a ficheros responsabilidad de IN-A Solutions SAC. Dichos
        datos serán tratados con las finalidades siguientes:
      </p>
      <ul>
        <li>Registro como usuario de la Web.</li>
        <li>
          Gestión, administración y control de los servicios y productos
          contratados a través de la Web.
        </li>
        <li>
          Envío de comunicaciones comerciales o promocionales (en el supuesto
          que lo autorices expresamente en el momento oportuno), propias o de
          colaboradores.
        </li>
      </ul>
      <p>
        El Usuario manifiesta expresamente que ha sido debidamente informado de
        todas las finalidades antes mencionadas. Asimismo, a través de la
        aceptación de la presente Política de Privacidad, el Usuario autoriza y
        otorga su consentimiento, de manera previa, libre, expresa e inequívoca
        y gratuita, para el tratamiento de sus IN-A Solutions S.A.C. Asimismo,
        el Usuario reconoce y acepta que cualquier tratamiento de la Información
        del Usuario que sea necesario para la ejecución de la relación
        contractual que vincula al Usuario con IN-A Solutions S.A.C. no
        requieren de su consentimiento y sólo de ser informado al Usuario.
      </p>
      <p>
        IN-A Solutions S.A.C. se compromete a no divulgar o compartir los Datos
        Personales del Usuario, sin que haya prestado el debido consentimiento
        para ello, con excepción de los siguientes casos:
      </p>
      <ul>
        <li>
          Solicitudes de información de autoridades públicas en ejercicio de sus
          funciones y el ámbito de sus competencias.
        </li>
        <li>Solicitudes de información en virtud de órdenes judiciales.</li>
        <li>Solicitudes de información en virtud de disposiciones legales. </li>
      </ul>
      <h2>4. Seguridad</h2>
      <p>
        Con el objetivo de prestar un mejor servicio y garantizar la integridad
        de los datos, IN-A Solutions SAC establece los mecanismos y medidas de
        índole técnica y organizativa acordes a la normativa de seguridad
        vigente. En concreto, de acuerdo con la normativa vigente, te informamos
        que IN-A Solutions SAC cumple con las medidas de seguridad de nivel
        básico.
      </p>
      <h2>5.Cookies</h2>
      <p>
        La mayoría de los navegadores aceptan las cookies de forma automática,
        pero le permiten al Usuario cambiar la configuración de su navegador
        para que rechace la instalación de cookies, sin que ello perjudique su
        acceso y navegación.
      </p>

      <h2>6. Alcance y Plazo de conservación</h2>
      <p>
        Esta Política de Privacidad se aplicará al tratamiento de los datos
        personales del Usuario contenidos al menos 30 días en los respectivos ,
        o mientras no se solicite su cancelación por el titular del dato en
        cumplimiento con la Ley.
      </p>
      <h2>7. Derechos de Acceso, Rectificación, Cancelación y Oposición</h2>
      <p>
        IN-A Solutions S.A.C. pone a conocimiento del Usuario que los derechos
        de acceso, rectificación, oposición y cancelación de la Información del
        Usuario, así como los demás derechos concedidos por la normativa de
        protección de datos personales, se podrán ejercer mediante petición
        dirigida a: inasolutionssac@gmail.com
      </p>
      <h2>8. Edad de Consentimiento</h2>
      <p>
        Al brindar sus datos personales a IN-A Solutions S.A.C., el Usuario
        declara tener al menos dieciocho años de edad o ser tutor de un menor de
        edad para otorgar el consentimiento de forma válida de acuerdo a la Ley
        no llevará a cabo voluntariamente el tratamiento de Datos Personales
        relativos a menores de edad, salvo que se cuenten con el debido
        consentimiento de acuerdo a la norma aplicable. En el supuesto de que se
        tenga conocimiento que los Datos Personales recogidos corresponden a un
        menor de edad sin autorización de su tutor legal, se adoptarán las
        medidas oportunas para eliminarlos.
      </p>
      <h2>9. Modificaciones de la Política de Privacidad</h2>
      <p>
        IN-A Solutions S.A.C. se reserva expresamente el derecho a modificar,
        actualizar o completar en cualquier momento la presente Política de
        Privacidad. Cualquier modificación, actualización o ampliación producida
        en la presente Política de Privacidad será inmediatamente publicada en
        el sitio web de IN-A Solutions S.A.C., por lo cual el Usuario estará
        enterado de qué información recopilamos y bajo qué circunstancias, y se
        solicitará el consentimiento al acceder nuevamente a dichos servicios.
      </p>
    </section>
  );
};

export default Privacidad;
